
import { BRadio } from 'buetify/lib/components';
import { defineComponent, shallowRef } from 'vue';

export default defineComponent({
	name: 'radio-simple-example',
	components: {
		BRadio
	},
	setup() {
		return {
			radio: shallowRef('Jack')
		};
	}
});
